import { graphql } from "gatsby";
import { GenericPage } from "../layouts";

export default GenericPage;

export const PageQuery = graphql`
  query PageQuery($id: String!) {
    graphCmsPage(id: { eq: $id }) {
      ...GenericPageFragment
    }
    allGraphCmsAward(sort: { fields: sortDate, order: DESC }) {
      nodes {
        mediaLink
        channelName
        awardimage {
          url
        }
      }
    }
  }
`;
